<template>

   <el-card shadow="hover">
        <el-row align="middle">
            <el-col :span="12">
                <div class="text-left">
                    <h3>{{ $t('tablesBackup') }}</h3>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="text-right">
                    <el-button size="default" @click="addItem()" type="primary" icon="plus">
                        {{ $t('createBackup') }}
                    </el-button>
                </div>
            </el-col>
        </el-row>

        <br>

        <el-table :data="pagedTableData" :empty-text="$t('noData')" border style="width: 100%" stripe v-loading="isLoading">

            <el-table-column type="index" :index="indexMethod"> </el-table-column>
            <el-table-column sortable :label="$t('date')" width="160">
                <template #default="scope">
                    {{
                        reformatDate(scope.row.insert_datetime, {
                            inPattern: 'YYYY-MM-DD HH:mm:ss',
                            outPattern: 'DD MMM YYYY - HH:mm',
                            locale: $i18n.locale
                        })
                    }}
                </template>
            </el-table-column>
            <el-table-column sortable :label="$t('code')" prop="code"> </el-table-column>
            <el-table-column sortable :label="$t('tables')" prop="tables">
                <template #default="scope">
                    {{ showTables(scope.row.tables) }}
                </template>
            </el-table-column>

            <el-table-column align="right" width="275">

                <template #header>
                    <el-input v-model="search" size="small" :placeholder="$t('search')" />
                </template>

                <template #default="scope">

                    <el-button size="small" type="success" @click="downloadFile(scope.$index, scope.row)" icon="download">
                        {{ $t('download') }}
                    </el-button>
                    <el-button size="small" type="danger" @click="deleteItem(scope.$index, scope.row)" icon="delete">
                        {{ $t('delete') }}
                    </el-button>

                </template>

            </el-table-column>

        </el-table>

        <div style="text-align: center; margin-top: 20px">

            <el-pagination
                background
                layout="prev, pager, next"
                @current-change="setPage"
                :hide-on-single-page="true"
                :page-size="pageSize"
                :total="total">
            </el-pagination>

        </div>
  </el-card>

  <el-dialog
    @close="updateTableItemsInactivity()"
    :title="$t('createTablesBackup')"
    v-model="tbDialog"
    width="40%">

    <el-form
        :model="tb"
        :rules="rules"
        ref="createTb"
        label-width="150px"
        label-position="left"
        hide-required-asterisk>

        <el-form-item prop="tables" :label="$t('tables')">
            <el-select
                multiple
                filterable
                v-model="tb.tables"
                :placeholder="$t('select')"
                :no-data-text="$t('noData')"
                :no-match-text="$t('noMatchingData')"
                @change="updateTableItemsInactivity()"
                style="width: 100%">
                <el-option
                    v-for="item in tablesList"
                    :key="item.value"
                    :value="item.value"
                    :label="item['label_' + $i18n.locale]"
                    :disabled="!!item.inactive">
                </el-option>
            </el-select>
        </el-form-item>

    </el-form>

    <template #footer>
        <span class="dialog-footer">
            <el-button @click="cancelForm()">{{ $t('cancel') }}</el-button>
            <el-button type="primary" @click="submitForm()">{{ $t('confirm') }}</el-button>
        </span>
    </template>

  </el-dialog>

</template>

<script>
import { mapState, mapActions } from 'vuex'

import { reformatDate } from '@/utils/chronos'

import { DATABASE_API_BASE_URL } from '@/utils/constants'
import { downloadByUrl } from '@/utils/functions'

import { v4 as uuidv4 } from 'uuid'

import AccessManager from '@/mixins/access-manager'

export default {
    name: 'TablesBackup',
    mixins: [AccessManager],
    data() {
      return {
        tablesList: [],
        primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
        tb: {
            tables: []
        },
        rules: {
            tables: [
                {
                    required: true,
                    message: this.$t('fieldRequired'),
                    trigger: 'blur',
                }
            ]
        },
        isLoading: false,
        tbDialog: false,
        tableData: [],
        pageSize: 6,
        search: '',
        total: 0,
        page: 1
      }
    },
    created() {

        this.fetchTb()

    },
    computed: {
        ...mapState({
            userData: state => state.session.userData
        }),
        pagedTableData() {

            this.total = this.searching.length

            return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)

        },
        searching() {

            if (!this.search) {
                this.total = this.tableData.length
                return this.tableData
            }

            this.page = 1

            return this.tableData.filter(data => (
                data.code.toLowerCase().includes(this.search.toLowerCase()) ||
                data.date.toLowerCase().includes(this.search.toLowerCase())
            ))

        }
    },
    methods: {
        reformatDate,
        ...mapActions({
            fetchDataDB: 'data/fetchDataDB',
        }),
        setPage (val) {

            this.page = val

        },
        indexMethod(index) {

            return index + ((this.page - 1) * this.pageSize) + 1

        },
        updateTableItemsInactivity(){

            this.tablesList.forEach((table, i) => {

                if (!this.tb.tables.length) {

                    this.tablesList[i].inactive = false

                } else {

                    this.tablesList[i].inactive = table.value === '*'
                                                ? !this.tb.tables.includes('*')
                                                : this.tb.tables.includes('*')

                }

            })

        },
        showTables(tables){
            if (tables){
                let result = ''
                let tab = tables.split(';')

                for (let i = 0; i < tab.length; i++) {

                    let table = this.tablesList.find(a => a.value == tab[i])

                    if (table) {
                        result += (table['label_' + this.$i18n.locale])
                        if (i + 1 < tab.length) result += ' / '
                    }

                }

                return result
            }

            return ''
        },
        async fetchTb(){

            this.isLoading = true

            let resp0 = await this.fetchDataDB({ action: 'getTablesList' })

            if (resp0.success && resp0.data) this.tablesList = resp0.data

            let resp = await this.fetchDataDB({ action: 'getTablesBackups' })

            if (resp.success){

                if (resp.data) this.tableData = resp.data

            }else{

                this.$message.error(this.$t('anErrorOccured'))

            }

            this.isLoading = false

        },
        addItem(){

            this.tb.tables = []

            this.tbDialog = true

        },
        downloadFile(index, item){

            downloadByUrl(DATABASE_API_BASE_URL + 'Backups/' + item.code + '.sql')

        },
        deleteItem(index, item){

            this.$confirm(this.$t('deleteBackupQuestion'), this.$t('delete'), {
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning',
            })
            .then(async () => {

                let task = await this.fetchDataDB({
                    action: 'deleteTablesBackup',
                    params: {
                        id: item.id
                    }
                })

                if (task.success){

                    this.$message.success(this.$t('success'))
                    this.fetchTb()

                }else{

                    this.$message.error(this.$t('anErrorOccured'))

                }

            })
            .catch(() => {

            })

        },
        submitForm(){

            this.$refs.createTb.validate(async (valid) => {
                if (valid) {

                    let mes = this.$message({ message: this.$t('pleaseWait...'), duration: 0 })

                    let task = await this.fetchDataDB({
                        action: 'makeTablesBackup',
                        params: {
                            code: uuidv4(),
                            tables: this.tb.tables.join(';')
                        }
                    })

                    mes.close()

                    if (task.success) this.$message.success(this.$t('success'))
                    else this.$message.error(this.$t('anErrorOccured'))

                    this.cancelForm()

                    this.fetchTb()

                } else {
                    return false
                }
            })

        },
        cancelForm(){

            this.$refs.createTb.resetFields()

            this.tbDialog = false

        }
    },
}
</script>